html [type='button'],
.btn[type='button'] {
	-webkit-appearance: none !important;
}

.btn {
	padding: 8px 24px;

	&.rounded,
	&.btn-rounded {
		border-radius: 40px;
	}

	&.btn-wide {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	&.btn-loading {
		.btn-label {
			margin-left: 15px;
		}
	}

	&.btn-loading.btn-lg {
		.btn-label {
			margin-left: 0px;
		}
	}

	&.btn-loading.btn-block {
		.btn-label {
			margin-left: 0px;
		}
	}

	&.btn-loading.btn-xs {
		.btn-label {
			margin-left: 20px;
		}
	}

	&.btn-loading.btn-sm {
		.btn-label {
			margin-left: 25px;
		}
	}
}

.btn-secondary {
	color: $foreground;
	background-color: $gray-200;
}

.btn-icon {
	width: 34px;
	height: 34px;
	padding: 0;

	[class^='i-'],
	.icon {
		vertical-align: middle;
		margin: 0 2px;
		-webkit-font-smoothing: subpixel-antialiased;
	}
}

.btn-icon-text {
	[class^='i-'],
	.icon {
		vertical-align: middle;
		margin: 0 2px;
		-webkit-font-smoothing: subpixel-antialiased;
	}
}

.btn-facebook {
	background: $facebook;
	border-color: $facebook;

	&:hover {
		background: darken($facebook, 5%);
		border-color: darken($facebook, 5%);
	}

	&:focus {
		box-shadow: 0 0 0 0.2rem rgba($facebook, 0.5);
	}
}

.btn-facebook {
	background: $google;
	border-color: $google;

	&:hover {
		background: darken($google, 5%);
		border-color: darken($google, 5%);
	}

	&:focus {
		box-shadow: 0 0 0 0.2rem rgba($google, 0.5);
	}
}

.btn-twitter {
	background: $twitter;
	border-color: $twitter;

	&:hover {
		background: darken($twitter, 5%);
		border-color: darken($twitter, 5%);
	}

	&:focus {
		box-shadow: 0 0 0 0.2rem rgba($twitter, 0.5);
	}
}

.btn-outline-light {
	color: #a6aaaf;
	background-color: transparent;
	background-image: none;
	border-color: #a6aaaf;

	&:hover {
		color: #94999f;
		background-color: transparent;
		border-color: #94999f;
	}
}

.btn-outline-facebook {
	color: $facebook;
	border-color: $facebook;
	background: rgba($gray-200, 0.6);

	&:hover {
		background: darken($facebook, 5%);
		border-color: darken($facebook, 5%);
	}

	&:focus {
		box-shadow: 0 0 0 0.2rem rgba($facebook, 0.5);
	}
}

.btn-outline-google {
	color: $google;
	border-color: $google;
	background: rgba($gray-200, 0.6);

	&:hover {
		background: darken($google, 5%);
		border-color: darken($google, 5%);
	}

	&:focus {
		box-shadow: 0 0 0 0.2rem rgba($google, 0.5);
	}
}

.btn-outline-twitter {
	color: $twitter;
	border-color: $twitter;
	background: rgba($gray-200, 0.6);

	&:hover {
		background: darken($twitter, 5%);
		border-color: darken($twitter, 5%);
	}

	&:focus {
		box-shadow: 0 0 0 0.2rem rgba($twitter, 0.5);
	}
}

.btn-outline-email {
	background: rgba($gray-200, 0.6);
}

.btn-spinner {
	width: 1em;
	height: 1em;
	background: transparent;
	border-radius: 50%;
	margin: 0 16px 0 0;
	border: 2px solid transparent;
}

.btn-checkbox {
	.checkbox {
		display: inline;
	}
}

.btn.btn-outline-light.btn-svg {
	border-color: $gray-700;

	&.active,
	&:hover {
		background: $primary;
		border-color: $primary;

		svg {
			fill: #ffffff;
		}
	}

	&:focus {
		box-shadow: none !important;
	}
}

@each $name, $value in $theme-colors {
	.btn-#{$name} {
		.btn-spinner {
			animation: btn-glow-#{$name} 1s ease infinite;
		}
	}

	@keyframes btn-glow-#{$name} {
		0% {
			box-shadow: 0 0 0 0.4em darken($value, 10%), 0 0 0 0.1em darken($value, 10%);
			transform: rotate(360deg);
		}

		50% {
			border-top-color: #ffffff;
		}

		100% {
			box-shadow: 0 0 0 0.4em darken($value, 10%), 0 0 0 3.6em transparent;
		}
	}
}

.btn-primary {
	color: $btn-primary-color;
}

.btn-outline {
  border: 5px solid #72FFB3 !important;
  border-radius: 30px !important;
  color: #14D299 !important;
  background-color: #F5F5F5;

  font-size: 18px !important;
  line-height: 24px !important;
  font-weight: 900 !important;

  width: 100%;
  text-transform: unset !important;
}

.btn-sm {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 8px;

	i {
		font-size: 16px;
		margin-right: 4px;

		&.icon-right {
			margin-left: 4px;
			margin-right: 0px;
		}
	}
}

.btn-xs {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 4px 8px;
	font-size: 10px;
}

.btn-outline-secondary {
	border: solid 1px #cacbcd;
	background: transparent;
	color: #aeb0b3;

	&:hover,
	&:active,
	&:focus {
		color: $btn-outline-secondary-color-hover !important;
		border-color: $btn-outline-secondary-color-hover !important;
		background: transparent !important;
		box-shadow: none !important;
	}
}

.btn-outline-white {
	color: #fff;
	border-color: #fff;
	background-color: initial;

	&:hover {
		color: $primary;
		background: #fff;
	}
}

.icon-button {
	padding: 0;
	font-size: 14px;
	width: 34px;
	height: 34px;
	line-height: 34px;
	border-radius: 50px;
}

button {
	position: relative;
	transition: all 0.15s ease-in-out;
	&.loading {
		padding-left: 36px;
	}
	.btn-spinner {
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		left: 10px;
	}
}

.btn-transparent {
	background: transparent;
	border: none;
}

.btn {
	font-family: Raleway, sans-serif;
	text-transform: uppercase;

	.material-icons {
		font-size: 16px;
	}
}

.btn-lg {
	font-size: 16px;
	padding: 16px 40px;

	.material-icons {
		margin-right: 8px;
		font-weight: normal;
		font-size: 24px;
	}
}
