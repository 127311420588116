@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

[dir='rtl'] {
	//Text align
	@each $breakpoint in map-keys($grid-breakpoints) {
		@include media-breakpoint-up($breakpoint) {
			$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
			.text#{$infix}-left {
				text-align: right !important;
			}
			.text#{$infix}-right {
				text-align: left !important;
			}
		}
	}

	// Align
	@each $breakpoint in map-keys($grid-breakpoints) {
		@include media-breakpoint-up($breakpoint) {
			$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

			.float#{$infix}-left {
				@include float-right;
			}
			.float#{$infix}-right {
				@include float-left;
			}
		}
	}

	// Margin and Padding
	@each $breakpoint in map-keys($grid-breakpoints) {
		@include media-breakpoint-up($breakpoint) {
			$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

			@each $prop, $abbrev in (margin: m, padding: p) {
				@each $size, $length in $spacers {
					.#{$abbrev}r#{$infix}-#{$size},
					.#{$abbrev}x#{$infix}-#{$size} {
						#{$prop}-left: $length !important;
						margin-right: unset !important;
					}
					.#{$abbrev}l#{$infix}-#{$size},
					.#{$abbrev}x#{$infix}-#{$size} {
						#{$prop}-right: $length !important;
						margin-left: unset !important;
					}
				}
			}
		}
	}

	// input group
	.input-group > .input-group-prepend > .btn,
	.input-group > .input-group-prepend > .input-group-text,
	.input-group > .input-group-append:not(:last-child) > .btn,
	.input-group > .input-group-append:not(:last-child) > .input-group-text,
	.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
	.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
		@include border-left-radius(0);
		@include border-right-radius($input-border-radius);
	}

	.input-group > .input-group-append > .btn,
	.input-group > .input-group-append > .input-group-text,
	.input-group > .input-group-prepend:not(:first-child) > .btn,
	.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
	.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
	.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
		@include border-right-radius(0);
		@include border-left-radius($input-border-radius);
	}

	.input-group {
		> .form-control,
		> .custom-select {
			&:not(:last-child) {
				@include border-left-radius(0);
				@include border-right-radius($input-border-radius);
			}
			&:not(:first-child) {
				@include border-right-radius(0);
				@include border-left-radius($input-border-radius);
			}
		}
	}

	// Button group
	.btn-group {
		// Reset rounded corners
		> .btn:not(:last-child):not(.dropdown-toggle),
		> .btn-group:not(:last-child) > .btn,
		> .btn:not(:first-child),
		> .btn-group:not(:first-child) > .btn {
			//   @include border-left-radius(0);
			@include border-radius(0);
		}
	}
}
