$font-family-heading: 'Nunito', sans-serif;
$font-family-base: 'Nunito', sans-serif;
$font-size-base: 0.813rem;

// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);


$blue: #263db5 !default;
$indigo: #6610f2 !default;
$purple: #3b9cd9 !default;
$pink: #e83e8c !default;
$red: #d22346 !default;
$orange: #e97d23 !default;
$yellow: #ffc107 !default;
$green: #4caf50 !default;
$teal: #20c997 !default;
$cyan: #9c27b0 !default;

$branded1: #143968;
$branded2: #f27407;
$branded3: #94cef2;
$branded4: #262626;
$branded5: #95aabf;
$branded6: #202B38;

$brand: #666666;

$dark1: #262626;
$dark2: #404040;
$dark3: #666666;

$grey1: #aeb0b3;
$grey2: #d9d9d9;
$grey3: #f7f7f7;

$primary-base: $branded1;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #bbb !default;
$gray-600: lighten(desaturate($primary-base, 40), 4%) !default;
$gray-700: darken(desaturate($primary-base, 40), 0%) !default;
$gray-800: darken(desaturate($primary-base, 40), 8%) !default;
$gray-900: darken(desaturate($primary-base, 40), 12%) !default;
$black: darken($primary-base, 60%) !default;

$primary: $primary-base !default;
$secondary: $gray-800 !default;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-500 !default;
$dark: $gray-900 !default;
$background: $white !default;
$foreground: $gray-900 !default;
$heading: darken($foreground, 8%);

$colors: (
	'blue': $blue,
	'indigo': $indigo,
	'purple': $purple,
	'pink': $pink,
	'red': $red,
	'orange': $orange,
	'yellow': $yellow,
	'green': $green,
	'teal': $teal,
	'cyan': $cyan,
	'white': $white,
	'gray': $gray-600,
	'gray-dark': $gray-800
) !default;

$theme-colors: (
	primary: $primary,
	secondary: $secondary,
	success: $success,
	info: $info,
	warning: $warning,
	danger: $danger,
	light: $light,
	gray-100: $gray-100,
	gray-200: $gray-200,
	gray-300: $gray-300,
	gray-400: $gray-400,
	dark: $dark
) !default;

/**
BUTTONS
*/
$btn-primary-color: $white;
$btn-outline-secondary-color-hover: $branded2;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1170px
) !default;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';
