.table thead th,
.table th,
.table td {
	border: 0px;
  padding: .25rem;
}

.table {
	tr {
		td,
		th {
			background: #fff;
			line-height: normal;
			padding: 8px;
			transition: all 0.3s ease-in-out;

			&:first-child {
				left: 0;
				// max-width: 300px;
				// min-width: 200px;
				padding-left: 0;
				position: sticky;
				z-index: 1;

				&::after {
					display: block;
					width: 1px;
					background: #f3f1f1;
					content: ' ';
					position: absolute;
					right: 0;
					top: 0;
					height: 100%;
				}
			}
		}
	}

	thead {
		th {
			color: #636363;
			font-size: 8px;
			font-weight: bold;
			text-transform: uppercase;

			.material-icons {
				font-size: 14px;
				line-height: normal;
				margin-left: 5px;
				position: relative;
				top: 3px;
			}

			&.hasorder {
				cursor: pointer;

				&:hover {
					color: $secondary;
				}
			}

			&.active-order {
				color: $primary;
				border-bottom: 2px solid $primary;
			}

			&.hasicon {
				font-size: 28px;
				padding: 4px 8px;
				vertical-align: middle;
			}
		}
	}

	tbody {
		tr {
			td {
				color: #848484;
				font-size: 9px;
				font-weight: normal;

				&:first-child {
					color: #313131;
				}
			}

			&:hover {
				th,
				td {
					background: #fcfcfc;
				}

				.product-status {
					height: 34px;
					transition: height 500ms;
				}
			}
		}
	}

	.product-status {
		width: 4px;
		height: 12px;
		border-radius: 4px;
		margin-right: 10px;
		background-color: $primary;
		margin-top: auto;
		margin-bottom: auto;
	}
}

// @media (max-width: 1440px) {
// 	.table {
// 		display: table;
// 	}
// }

@media (min-width: 900px) {
	.table {
		display: table;
	}
}
