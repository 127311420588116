@import 'vars';
@import 'header';
@import 'sidebar';
@import 'footer';

#content {
	width: 100%;
	padding: 0;
	min-height: calc(100vh - #{$topbar-height});
	transition: all 0.3s;
	margin-top: $topbar-height;
	float: right;
	display: flex;
	flex-direction: column;
	margin-left: $sidebar-min-width;
  background-color: #fff;

  &.layout-toggle-sidebar {
		margin-left: $sidebar-toggle-width;
	}

	&.sidebar-active {
		margin-left: $sidebar-width;
	}

	@include media-breakpoint-down(md) {
		&.sidebar-active {
			margin-left: 0px;
		}
	}
}

.module-loader,
.loader {
	position: fixed;
	background: rgba($background, 0.5);
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 9;
	.loader,
	.spinner {
		position: fixed;
		top: 45%;
		left: calc(50% + 56px);
		z-index: inherit;
	}
}
