.customizer {
	position: fixed;
	z-index: 9999;
	top: 100px;
	right: -380px;
	transition: 0.3s all ease-in-out;
	width: 380px;
	&.open {
		right: 0;
	}
	.handle {
		position: absolute;
		display: flex;
		top: 8px;
		left: -36px;
		background: $primary;
		cursor: pointer;
		padding: 10px 8px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		box-shadow: -3px 0px 4px rgba(0, 0, 0, 0.06);
		color: $white;
		font-size: 20px;
		transition: 0.3s all ease-in-out;
		&:hover {
			background: $primary;
			color: $white;
		}
	}
	.customizer-body {
		// padding: 1rem;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		background: $background;
		box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
		max-height: calc(100vh - 140px);
		overflow-y: scroll;
		overflow-x: visible;
		.layouts {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -8px;
			.layout-box {
				position: relative;
				margin: 0 8px;
				box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.03);
				border-radius: 8px;
				overflow: hidden;
				cursor: pointer;
				width: calc(50% - 16px);
				border: 1px solid rgba(0, 0, 0, 0.08);
				img {
					width: 180px;
				}
				i {
					display: none;
					position: absolute;
					top: 0;
					text-align: center;
					right: 0;
					height: 24px;
					font-size: 18px;
					line-height: 24px;
					width: 32px;
					color: #ffffff;
					background: #663399;
					border-radius: 0 0 0 6px;
				}
				&.active {
					border: 1px solid $primary;
					i {
						display: inline-block;
					}
				}
			}
		}
		.colors {
			display: flex;
			flex-wrap: wrap;
			.color {
				width: 36px;
				height: 36px;
				display: inline-block;
				border-radius: 50%;
				margin: 8px;
				text-align: center;
				box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.03);
				cursor: pointer;
				&.purple {
					background: #663399;
				}
				&.blue {
					background: #2f47c2;
				}
				i {
					display: none;
					color: #ffffff;
					font-size: 18px;
					line-height: 36px;
				}
				&.active {
					i {
						display: unset;
					}
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.customizer {
		width: 280px;
		right: -280px;
	}
}
