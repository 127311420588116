.dropdown-toggle {
	position: relative;
	&.btn {
		padding-right: 28px;
	}
	&::after {
		position: absolute;
		top: calc(50% - 2px);
		right: 10px !important;
	}
}
.dropdown-menu {
	border: 0;
	box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.08);
}
.dropdown-item {
	padding: 0.42rem 1.5rem;
}
.menu-icon-grid {
	width: 220px;
	padding: 0 8px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	> a {
		display: inline-flex;
		width: 6rem;
		flex-direction: column;
		align-items: center;
		padding: 18px 12px;
		color: $gray-800;
		border-radius: 4px;
		i {
			font-size: 28px;
			margin-bottom: 4px;
		}
		&:hover {
			background: $primary;
			color: $white;
		}
	}
}
.mega-menu {
	position: static;
	.dropdown-toggle {
		background: transparent;
	}
	.dropdown-menu {
		width: calc(100% - 120px);
		max-width: 1200px;
		padding: 0;
		overflow: hidden;
		max-height: calc(100vh - 100px);
		overflow-y: scroll;
		.bg-img {
			background: linear-gradient(to right, $primary, $info);
		}
		.bg-img,
		.bg-img .title {
			color: #ffffff;
		}

		ul.links {
			list-style: none;
			margin: 0;
			padding: 0;
			column-count: 2;
			li a {
				display: inline-block;
				margin-bottom: 8px;
				color: $gray-600;
				&:hover {
					color: $primary;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.mega-menu {
		.dropdown-menu {
			left: 0 !important;
			right: auto !important;
			width: calc(100% - 10px);
		}
	}
}

[dir='rtl'] {
	.mega-menu {
		.dropdown-menu {
			left: auto !important;
			right: 16px !important;
		}
	}
	.notification-dropdown.dropdown-menu,
	.header-account-dropdown.dropdown-menu,
	.menu-icon-grid-dropdown.dropdown-menu {
		right: auto !important;
		left: 0 !important;
	}
}
