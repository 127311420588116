@import url('https://fonts.googleapis.com/css?family=Inter:400,500,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800;900&display=swap');

@import 'variables';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~ngx-toastr/toastr.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~highlight.js/styles/github.css';
@import '~ladda/dist/ladda.min.css';
@import '~dragula/dist/dragula.css';
@import '~@fullcalendar/common/main.css';
@import '~@fullcalendar/daygrid/main.css';

@import '~bootstrap/scss/bootstrap.scss';
@import 'bootstrap-rtl.scss';

@import 'globals/globals';

@import 'material/custom.scss';

.material-icons {
	font-family: 'Material Icons';
}

.mat-form-field {
	font-family: 'Inter', sans-serif !important;
	letter-spacing: 0px;
}

button,
a,
.dropdown-item {
	outline: none !important;
}

.container-fluid {
	max-width: 100%;
}

.line-clamp {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.card-head-bar {
	background: linear-gradient(180deg, #069db5 0%, #038ca0 31.33%, #0c5173 94.67%);
	border-radius: 20px 20px 60px 20px;

	.container-router-link {
		.active-link {
			font-family: 'Inter';
			color: #fff;
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			text-decoration: underline;
		}

		a {
			font-family: 'Inter';
			color: #fff;
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			// &:hover {
			//   color: #fff;
			// }
		}

		i {
			color: #fff;
		}
	}

	.container-title {
		h1 {
			font-family: Inter;
			font-size: 28px;
			font-weight: 700;
			line-height: 36px;
			text-align: left;
			color: #2eeac2;
		}
	}

	.container-info-created {
		font-family: 'Inter';
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		text-align: left;
		color: #fff;
	}

	.container-button {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;

		.section-buttons {
			display: flex;
		}

		.btn-cancel {
			button {
				height: 40px;
				border-radius: 100px;
				min-width: 170px;
				border: 1px solid #14d2aa;
				background-color: #14d2aa;

				span {
					font-family: 'Inter';
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;
					text-transform: uppercase;
					color: #ffffff;
				}

				i {
					color: #ffffff;
				}
			}
		}

		.btn-report {
			button {
				height: 40px;
				border-radius: 100px;
				min-width: 170px;
				border: 1px solid #14d2aa;
				background-color: #14d2aa;

				span {
					font-family: 'Inter';
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;
					text-transform: uppercase;
					color: #ffffff;
				}

				i {
					color: #ffffff;
				}
			}
		}

		.btn-back {
			height: 40px;
			border-radius: 100px;
			color: #fff;
			font-family: 'Inter';
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;
			text-transform: uppercase;
			min-width: 170px;
			border: 1px solid #fff;

			button {
				::ng-deep .mat-badge-content {
					color: #ffffff;
					background: #fff;
				}

				&.mat-stroked-button {
					border: none;
					padding: 0 15px !important;
					line-height: 25px !important;
				}

				::ng-deep .mat-button-focus-overlay {
					display: none;
				}
			}
		}

		.cicle-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			border-radius: 100px;
			background: #ffffff;
			cursor: pointer;
		}

		.cicle-icon:hover {
			background: #14d2aa;

			i {
				color: #ffffff;
			}
		}
	}
}
