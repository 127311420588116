$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

$num-of-classes: 130;
$directions: ('top', 'bottom', 'left', 'right');
$types: ('margin', 'padding');
$queries: (
	$screen-xs: 'xs',
	$screen-sm: 'sm',
	$screen-md: 'md',
	$screen-lg: 'lg'
);
@mixin generate-margins {
	@each $type in $types {
		@each $direction in $directions {
			@for $i from 0 through ($num-of-classes) - 1 {
				.#{$type}-#{$direction}-#{$i} {
					#{$type}-#{$direction}: (#{$i}em);
				}
			}
		}
		@each $query, $z in $queries {
			@media (min-width: #{$query}) {
				@each $direction in $directions {
					@for $i from 0 through ($num-of-classes) - 1 {
						.#{$type}-#{$direction}-#{$z}-#{$i} {
							#{$type}-#{$direction}: (#{$i}em);
						}
					}
				}
			}
		}
	}
}
@include generate-margins();
